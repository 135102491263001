import { userConstants } from "../Constants/user.constants";
import { cartConstants } from "../Constants/cart.constants";
import {PostData} from '../../Services/PostData';
import { userService } from '../user.services';
import { history } from '../history';

/* export const logOut=()=>(dispatch)=>{
    //localStorage.removeItem("user");
    dispatch({
        type: userConstants.LOGOUT
        //data: logoutsuccss
      });
    userService.logout();
} */

export function logOut() {
  return dispatch => {
    //console.log("Action Logout"); 
    userService.logout().then(response => {
      console.log("Action Logout: ",response); 
      dispatch({
        type: userConstants.LOGOUT
        //data: logoutsuccss
      });
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("cartData");
      localStorage.removeItem("cartAttributesData");
      history.push('/');
    });
  }
}

export function logInn(postData) {
    return dispatch => {
      var exactFilenameWithDir = "user/login-for-ractjs.php";
      var postTrough = "fetch";

      PostData(postTrough, exactFilenameWithDir, postData).then((result) => {
        let responseJson = result;
        let getResponseStatus = responseJson.status;
        console.log(responseJson);
        if(getResponseStatus === 200){
            //setLoggedIn(true);
            localStorage.setItem('user', JSON.stringify(responseJson.user));  
            dispatch(success(responseJson.user));
        } else if(getResponseStatus === 400) {
            //setIsError(true);
            let getResponseMessage = responseJson.message;
            dispatch(failure(getResponseMessage));
        }else{
            //setIsError(true);
            let error = "Woops! Something went wrong. Please try again later.";
            dispatch(failure(error));
        }
      }).catch((error) => {
        //setIsError(true); 
        let othererror = "Woops! Something went wrong. Please try again later";
        dispatch(failure(othererror));
      });
    }

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

export function logIn(postData) {
  
  return async dispatch => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin':'http://localhost:3000' },
      body: JSON.stringify(postData)
    };
    return await fetch('https://evisionstore.com/api/user/login-for-ractjs.php', requestOptions)
            .then((response) => response.json())
            .then((res) => {
              let responseJson = res;
              let getResponseStatus = responseJson.status;
              if(getResponseStatus === 200){
                localStorage.setItem('user', JSON.stringify(responseJson.user));  
                dispatch(success(responseJson.user));
              } else if(getResponseStatus === 400) {
                let getResponseMessage = responseJson.message;
                dispatch(failure(getResponseMessage));
              }else{
                let error = "Woops Woops! Something went wrong. Please try again later.";
                dispatch(failure(error));
              } 
            })
            .catch((error) => {
              let oterror = "Woops! Something went wrong. Please try again later.";
              dispatch(failure(oterror));
            });
  }

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

export function login2(email, password, cartItems) {
  return dispatch => {
      dispatch(request({email}));

      userService.login(email, password, cartItems)
          .then(
              res => { 
                console.log("Login Response: ",res);
                if(res.status === true){
                    localStorage.setItem('token', res.data.token);  
                    localStorage.setItem('user', JSON.stringify(res.data.customer));
                    history.push('/');
                    // Manage the cart section later
                    var cartItemsObj = [];
                    var cartAttributeItemsObj = [];
                    for (var q = 0, itemlen = res.data.cart_items.length; q < itemlen; q++) {
                      cartItemsObj[q] = {
                                        'prodId': res.data.cart_items[q].product_id,
                                        'prodModelo': res.data.cart_items[q].modelo,
                                        'prodName': res.data.cart_items[q].name, 
                                        'prodImage': res.data.cart_items[q].image_path, 
                                        'prodPrice': res.data.cart_items[q].price, 
                                        'prodQuantity': res.data.cart_items[q].qty,
                                        'prodSelectedVariationId': res.data.cart_items[q].variation_product_id
                                      }
                      if(res.data.cart_items[q].variation_product_id !== ""){
                        cartAttributeItemsObj[q] = {
                                        'prodId': res.data.cart_items[q].product_id,
                                        'prodVariationId': res.data.cart_items[q].variation_product_id,
                                        'prodAttribute': res.data.cart_items[q].checked_attributes
                                      }
                      }
                    }
                    
                    dispatch(cartrequest(cartItemsObj, cartAttributeItemsObj));
                    //dispatch(success(res.user));
                    dispatch(success(res.data.customer));
                }/*  else if(res.status === 400) {
                    let getResponseMessage = res.message;
                    dispatch(failure(getResponseMessage));
                } */else{
                    let error = "Woops! Something went wrong. Please try again later.";
                    dispatch(failure(error));
                }
              },
              error => {
                dispatch(failure(error.toString()));
              }
          );
  };

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function cartrequest(cartItemsObj, cartAttributeItemsObj) { return { type: cartConstants.ADD_TO_CART_WITH_LOGIN, cartItemsObj, cartAttributeItemsObj } }
}

export function signup(name, email, password, telephone, cartItems) {
  return dispatch => {
      dispatch(request({email}));

      userService.signup(name, email, password, telephone, cartItems)
          .then(
              res => {
                //console.log("REgister Response: ",res);
                //return false;
                if(res.status === true){
                    //var user = {'customer_id':res.customer_id, 'first_name':res.first_name, 'last_name':res.last_name, 'email':res.email};
                    //localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', res.data.token);  
                    localStorage.setItem('user', JSON.stringify(res.data.customer));
                    history.push('/');
                    //dispatch(success(user));
                    // Manage the cart section later
                    var cartItemsObj = [];
                    var cartAttributeItemsObj = [];
                    for (var q = 0, itemlen = res.data.cart_items.length; q < itemlen; q++) {
                      cartItemsObj[q] = {
                        'prodId': res.data.cart_items[q].product_id,
                        'prodModelo': res.data.cart_items[q].modelo,
                        'prodName': res.data.cart_items[q].name, 
                        'prodImage': res.data.cart_items[q].image_path, 
                        'prodPrice': res.data.cart_items[q].price, 
                        'prodQuantity': res.data.cart_items[q].qty,
                        'prodSelectedVariationId': res.data.cart_items[q].variation_product_id
                      }
                      if(res.data.cart_items[q].variation_product_id !== ""){
                        cartAttributeItemsObj[q] = {
                          'prodId': res.data.cart_items[q].product_id,
                          'prodVariationId': res.data.cart_items[q].variation_product_id,
                          'prodAttribute': res.data.cart_items[q].checked_attributes
                        }
                      }
                    }
                    
                    dispatch(cartrequest(cartItemsObj, cartAttributeItemsObj));

                    dispatch(success(res.data.customer));
                } /* else if(res.status === 400) {
                    let getResponseMessage = res.message;
                    dispatch(failure(getResponseMessage));
                } */else{
                    let error = "Woops! Something went wrong. Please try again later.";
                    dispatch(failure(error));
                }
                //history.push('/');
              },
              error => {
                dispatch(failure(error.toString()));
              }
          );
  };

  function request(email) { return { type: userConstants.SIGNUP_REQUEST, email } }
  function success(user) { return { type: userConstants.SIGNUP_SUCCESS, user } }
  function failure(error) { return { type: userConstants.SIGNUP_FAILURE, error } }
  function cartrequest(cartItemsObj, cartAttributeItemsObj) { return { type: cartConstants.ADD_TO_CART_WITH_LOGIN, cartItemsObj, cartAttributeItemsObj } }
}