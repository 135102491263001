import axios from 'axios';
import API_BASE_URL from '../config/api';

export const userService = {
    login,
    signup,
    logout,
    addToCartByCustomerId,
    updateCartByCustomerId,
    removeCartByCustomerId
};

/* function login(username, password, cartItems) {
    const cart_items = cartItems;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ username, password, cart_items })
    };

    return fetch(`https://www.evisionstore.com/api/user/login-for-ractjs.php`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            
            return res;
        });
} */

function login(email, password, cartItems) {
    const cart_items = cartItems;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ email, password, cart_items })
    };

    const apiUrl = `${API_BASE_URL}/auth/login`;

    return fetch(apiUrl, requestOptions)
        //.then(handleResponse)
        .then(res => res.json())
        .then(res => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return res;
        });
}

function signup(name, email, password, telephone, cartItems) {
    const signupApiUrl = `${API_BASE_URL}/auth/register`;
    const cart_items = cartItems;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({name, email, password, telephone, cart_items})
    };

    return fetch(signupApiUrl, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}

/* function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
} */

function logout() {
    //console.log("Token: ", localStorage.getItem('token'));
    // remove user from local storage to log user out
    const apiUrl = `${API_BASE_URL}/auth/logout`;
    const config = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    };

    return fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(res => {
            return res;
        });
}

function addToCartByCustomerId(prodId, prodQuantity, customerId, prodSelectedAttribte, prodSelectedVariationId) {
    const api_url = `${API_BASE_URL}/add-to-cart`; //`https://www.evisionstore.com/api/user/account/addtocart-react-beta2.php`
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"customer_id": customerId, "product_id": prodId, "qty": prodQuantity, "product_attributes": prodSelectedAttribte, "product_variation_id": prodSelectedVariationId})
    };

    return fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(err => {
            //console.log(err);
            throw err;
        });
}

function updateCartByCustomerId(prodId, prodQuantity, customerId, prodSelectedVariationId) {
    const apiUrl = `${API_BASE_URL}/update-cart`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "customer_id": customerId, "product_id": prodId, "qty": prodQuantity, "api_req_from": "react", 'variation_product_id': prodSelectedVariationId })
    };
    //`https://www.evisionstore.com/api/user/account/updatecart-react2.php`
    return fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(res => {
            return res;
        });
}

function removeCartByCustomerId(prodId, customerId, prodVariationId) {
    const apiUrl = `${API_BASE_URL}/delete-from-cart`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "customer_id": customerId, "product_id": prodId, 'variation_product_id': prodVariationId })
    };
    //`https://www.evisionstore.com/api/user/account/deletecart.php`
    return fetch(apiUrl, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function handleResponse(response) {
    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.code === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}