import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import queryString from 'query-string';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class BreadcrumbSection extends Component {

    render(){
        let params = this.props.match.params;
        let slug = params.promoId;
        return(
            <section className="breadcamp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Link to="/">Home &raquo; </Link>  {capitalizeFirstLetter(slug)}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(BreadcrumbSection);